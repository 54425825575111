import { Schema } from '../../Entities/Schema.entity';
import { createSchema } from '../../Utils/CreateSchema';

export const listingFeatureSchema: Schema = {
  ...createSchema({
    version: '1-8-0',
    description: 'The features that come with the listing',
    name: 'listing_feature',
    properties: {
      active_offer: {
        type: 'string',
        description: 'The active offer the listing is eligible for',
      },
      buy_online: {
        type: 'boolean',
        description: 'Is the listing eligible for buy online',
      },
      featured_listing: {
        type: 'boolean',
        description: 'Is the listing featured',
      },
      has_finance: {
        type: 'boolean',
        description: 'Does the listing have finance available',
      },
      has_hp: {
        type: 'boolean',
        description: 'Does the listing have hire purchase finance available',
      },
      has_pcp: {
        type: 'boolean',
        description: 'Does the listing have PCP finance available',
      },
      holding_image: {
        type: 'boolean',
        description: 'Holding image on the listing',
      },
      is_manufacturer_approved: {
        type: 'boolean',
        description: 'Is the listing manufacturer approved',
      },
      last_updated: {
        type: 'string',
        description: 'When was the latest update of the listing',
      },
      newly_listed: {
        type: 'boolean',
        description: 'Is the listing newly listed',
      },
      num_images: {
        type: 'number',
        description: 'Number of pictures on the listing',
      },
      price_rating: {
        type: 'string',
        description: 'The price rating of the listing',
      },
      price_reduced: {
        type: 'boolean',
        description: 'Is the price reduced',
      },
      promotions: {
        type: 'array',
        items: { type: 'string' },
        description: 'Active promotions the listing is eligible for',
      },
      discount_applied: {
        type: 'number',
        description: 'The discount applied to the listing',
      },
      has_driftrock_chkBox: {
        type: 'boolean',
        description: 'Does the lead form have a driftrock checkbox',
      },
      is_driftrock_chkBox_checked: {
        type: 'boolean',
        description: 'Is the driftrock checkbox checked',
      },
      is_oneclick_chkBox_checked: {
        type: 'boolean',
        description: 'Is the one click checkbox checked',
      },
      num_similarVehicles: {
        type: 'number',
        description: 'Number of similar vehicles',
      },
      has_oneClick_chkBox: {
        type: 'boolean',
        description: 'Does the lead form have a one click checkbox',
      },
      vehicle_guarantee: {
        type: 'string',
        description: 'Name of the guarantee for which the listing is eligible',
      },
      has_review: {
        type: 'boolean',
        description: 'Does the vehicle have a review visible',
      },
    },
    required: [
      'buy_online',
      'has_finance',
      'has_hp',
      'has_pcp',
      'holding_image',
      'last_updated',
      'num_images',
      'price_reduced',
    ],
  }),
};
