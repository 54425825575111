import { abandonCheckoutModalSchema } from './Checkout/AbandonCheckout/AbandonCheckoutModalSchema';
import { addFavouritesSchema } from './Favourites/AddFavourites/AddFavouritesSchema';
import { callStartSchema } from './CallStart/CallStartSchema';
import { callSubmitSchema } from './CallSubmit/CallSubmitSchema';
import { checkoutCashSchema } from './Checkout/CheckoutCash/CheckoutCashSchema';
import { checkoutDetailsSchema } from './Checkout/CheckoutDetails/CheckoutDetailsSchema';
import { checkoutFinanceSchema } from './Checkout/CheckoutFinance/CheckoutFinanceSchema';
import { checkoutStartSchema } from './Checkout/CheckoutStart/CheckoutStartSchema';
import { checkoutSummarySchema } from './Checkout/CheckoutSummary/CheckoutSummarySchema';
import { consentsSchema } from './Quantcast/Purpose/Consents/ConsentsSchema';
import { createAlertStartSchema } from './CreateAlert/CreateAlertStart/CreateAlertStartSchema';
import { createAlertConfirmationSchema } from './CreateAlert/CreateAlertConfirmation/CreateAlertConfirmationSchema';

import { experimentViewedSchema } from './ExperimentViewed/ExperimentViewedSchema';
import { filterInteractionSchema } from './FilterInteraction/FilterInteractionSchema';
import { hovStartSchema } from './Hov/HovStart/HovStartSchema';
import { leadSchema } from './Lead/LeadSchema';
import { leadStartSchema } from './Lead/LeadStart/LeadStartSchema';
import { leadSubmitSchema } from './Lead/LeadSubmit/LeadSubmitSchema';
import { legitimateInterestsSchema } from './Quantcast/Purpose/LegitimateInterests/LegitimateInterestsSchema';
import { listingFeatureSchema } from './ListingFeature/ListingFeatureSchema';
import { listingSchema } from './Listing/ListingSchema';
import { marketingSchema } from './Marketing/MarketingSchema';
import { pdpViewedSchema } from './PdpViewed/PdpViewedSchema';
import { purposeSchema } from './Quantcast/Purpose/PurposeSchema';
import { reservationStartSchema } from './Reservation/ReservationStart/ReservationStartSchema';
import { reservationSubmitDetailsSchema } from './Reservation/ReserveSubmitDetails/ReserveSubmitDetailsSchema';
import { reservationSubmitPaidSchema } from './Reservation/ReservationSubmitPaid/ReservationSubmitPaidSchema';
import { scrollToFinanceSchema } from './ScrollToFinance/ScrollToFinanceSchema';
import { searchFiltersSchema } from './SearchFilters/SearchFiltersSchema';
import { searchResultsSchema } from './SearchResults/SearchResultsSchema';
import { searchSortSchema } from './SearchSort/SearchSortSchema';
import { specialFeatureOptinsSchema } from './Quantcast/SpecialFeatureOptins/SpecialFeatureOptinsSchema';
import { userContextSchema } from './UserContext/UserContextSchema';
import { vehicleDetailsSchema } from './VehicleDetails/VehicleDetailsSchema';
import { ctaClickedSchema } from './CtaClicked/CtaClickedSchema';
import { nlsSchema } from './Nls/NlsSchema';
import { breadcrumbClickedSchema } from './BreadcrumbClicked/BreadcrumbClickedSchema';
import { latestAttributionSchema } from './Attribution/LatestAttributionSchema';
import { initialAttributionSchema } from './Attribution/InitialAttributionSchema';

export const schemas = [
  abandonCheckoutModalSchema,
  addFavouritesSchema,
  callStartSchema,
  callSubmitSchema,
  checkoutCashSchema,
  checkoutDetailsSchema,
  checkoutFinanceSchema,
  checkoutStartSchema,
  checkoutSummarySchema,
  consentsSchema,
  createAlertConfirmationSchema,
  createAlertStartSchema,
  experimentViewedSchema,
  filterInteractionSchema,
  hovStartSchema,
  initialAttributionSchema,
  latestAttributionSchema,
  leadSchema,
  leadStartSchema,
  leadSubmitSchema,
  legitimateInterestsSchema,
  listingFeatureSchema,
  listingSchema,
  marketingSchema,
  nlsSchema,
  pdpViewedSchema,
  purposeSchema,
  reservationStartSchema,
  reservationSubmitDetailsSchema,
  reservationSubmitPaidSchema,
  scrollToFinanceSchema,
  searchFiltersSchema,
  searchResultsSchema,
  searchSortSchema,
  specialFeatureOptinsSchema,
  userContextSchema,
  vehicleDetailsSchema,
  ctaClickedSchema,
  breadcrumbClickedSchema,
];
